<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card flat max-width="1100" width="100%" class="transparent mb-5">
        <v-toolbar flat class="transparent mb-3">
          <h1 class="headline black--text">
            {{ $t('talonario.conf_talonario') }}
          </h1>
          <v-spacer />
        </v-toolbar>
        <div v-if="loading" class="text-center py-5">
          <v-progress-circular width="2" color="primary" indeterminate />
        </div>
        <template v-if="!loading">
          <v-layout wrap>
            <v-flex lg12 md12>
              <v-card class="mb-4">
                <v-card-text v-if="es_trial">
                  <v-alert
                    v-if="talonarios.length === 0"
                    color="error"
                    outlined
                    icon="mdi-minus-circle"
                    :value="true"
                    >{{ $t('common.version_trial') }}</v-alert
                  >
                  <div class="text-center">
                    <v-btn text color="primary" dark @click="volverAtras">{{ $t('common.volver_atras') }}</v-btn>
                  </div>
                </v-card-text>
                <v-card-text v-else class>
                  <h3 class="title mb-3">
                    {{ $t('talonario.talonario_actuales') }}
                  </h3>
                  <v-list v-for="(item, index) in talonarios" :key="item.title" two-line>
                    <v-list-item href="#">
                      <v-list-item-avatar>
                        <v-icon color="teal">mdi-ballot</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.file }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.fecha }} ({{ item.recetas === '-' ? '∞' : item.recetas }}
                          {{ $tc('common.prescription', item.recetas) }})
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action v-if="permitirSubir">
                        <v-btn v-tooltip="$t('common.delete')" text icon @click="mostrarDialogEliminar(item.id, index)">
                          <v-icon color="deep-orange darken-4">mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider inset />
                  </v-list>
                  <v-alert v-if="talonarios.length === 0" outlined color="error" icon="mdi-pdf" :value="true">{{
                    $t('talonario.sin_talonarios')
                  }}</v-alert>
                  <br clear="all" />
                  <span v-if="permitirSubir">
                    <p>
                      <b>{{ $t('talonario.instrucciones_talonario') }}</b>
                    </p>
                    <ul>
                      <li>{{ $tc('talonario.instrucc_talon_li', 0) }}</li>
                      <li>{{ $tc('talonario.instrucc_talon_li', 1) }}</li>
                      <li>{{ $tc('talonario.instrucc_talon_li', 2) }}</li>
                    </ul>
                    <div class="py-5 text-center">
                      <v-progress-linear v-if="loading_upload" :size="50" color="teal" indeterminate />
                      <v-btn
                        data-test="settings-button-upload"
                        v-else
                        depressed
                        large
                        rounded
                        :disabled="disabledButton"
                        color="primary"
                        @click="seleccionarFile"
                      >
                        <v-icon class="mr-3">mdi-cloud-upload</v-icon>
                        {{ $t('talonario.subir_talonario') }}
                      </v-btn>
                      <input
                        id="filenew"
                        ref="filenew"
                        type="file"
                        accept="application/pdf, .pdf"
                        style="display: none"
                        @change="onFileChangeNuevo"
                      />
                      <br clear="all" />
                    </div>
                  </span>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </template>
      </v-card>
    </v-row>
    <!-- Modal Confirmar Talonario Subida/Eliminar -->
    <v-dialog v-model="confirmar.dialog" max-width="500px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{ confirmar.titulo }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="confirmar.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p>{{ confirmar.texto }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="confirmar.dialog = false">{{ $t('common.cancel') }}</v-btn>
          <v-btn
            :color="confirmar.color + ' darken-1'"
            depressed
            dark
            @click="
              confirmar.dialog = false;
              realizarAccion();
            "
            >{{ $t('common.confirm') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Alerts from '@/mixins/Alerts';
import { talonarioUrl, getHeader, getHeaderMultimedia } from '../../config/config';

export default {
  components: {},
  mixins: [Alerts],
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    talonarios: [],
    loading: true,
    nuevotalonario: '',
    disabledButton: false,
    mode: '',
    confirmar: {
      dialog: false,
      titulo: '',
      texto: '',
      icono: '',
      color: '',
      id: 0,
      accion: '',
      indice: 0,
    },
    loading_upload: false,
    es_trial: false,
    permitirSubir: false,
    custom_template:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== ''
        ? true
        : false,
  }),
  created() {
    if (this.authUser && this.authUser.trial) {
      this.es_trial = true;
    }
    const formData = new FormData();
    formData.append('user_id', this.authUser.id);
    formData.append('accion', 'indexTalonario');
    this.$http.post(talonarioUrl, formData, { headers: getHeader() }).then(response => {
      if (response.status === 200 && response.data) {
        this.talonarios = response.data.talonarios;
        this.loading = false;
        this.permitirSubir = response.data.permitirSubir;
      }
    });
  },

  methods: {
    seleccionarFile() {
      this.$refs.filenew.click();
    },
    onFileChangeNuevo() {
      this.nuevotalonario = this.$refs.filenew.files[0];
      this.confirmar.dialog = true;
      this.confirmar.titulo = this.$t('talonario.subir_talonario');
      this.confirmar.texto = this.$t('talonario.confirmar_subida_talonario', {
        msg: this.nuevotalonario.name,
      });
      this.confirmar.icono = 'mdi-cloud-upload';
      this.confirmar.color = 'green';
      this.confirmar.accion = 'subirNuevo';
    },
    realizarAccion() {
      const formData = new FormData();
      formData.append('user_id', this.authUser.id);
      formData.append('accion', this.confirmar.accion);

      if (this.confirmar.accion === 'subirNuevo') {
        this.loading_upload = true;
        this.disabledButton = true;
        formData.append('talonario', this.nuevotalonario);
        this.$http.post(talonarioUrl, formData, { headers: getHeaderMultimedia() }).then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            this.talonarios.push(response.data.talonario);
            this.toastSuccess(this.$t('success.save_common'));
          } else {
            this.toastError(this.$t('errors.try_again'));
          }
          this.loading_upload = false;
          this.disabledButton = false;
        });
      } else if (this.confirmar.accion === 'eliminarTalonario') {
        formData.append('talonario_id', this.confirmar.id);
        this.$http.post(talonarioUrl, formData, { headers: getHeader() }).then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            this.talonarios.splice(this.confirmar.indice, 1);
            this.toastSuccess(this.$t('success.delete_common'));
          } else {
            this.toastError(this.$t('errors.try_again'));
          }
        });
      }
    },
    volverAtras() {
      this.$router.push('/dashboard');
    },
    mostrarDialogEliminar(id, indice) {
      this.confirmar.dialog = true;
      this.confirmar.titulo = this.$t('talonario.eliminar_talonario');
      this.confirmar.texto = this.$t('talonario.confirmar_eliminar_talonario');
      this.confirmar.icono = 'mdi-delete';
      this.confirmar.color = 'red';
      this.confirmar.indice = indice;
      this.confirmar.id = id;
      this.confirmar.accion = 'eliminarTalonario';
    },
  },
};
</script>
